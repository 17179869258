import { Box, Button, Grid, IconButton } from "@mui/material";
import { memo, useState } from "react";
import { teamMember } from "../../Constants";
import { userAccess } from "../../utils/CommonFunction";
import AddNewTaskModal from "./AddNewTaskModal";
import { usePermissions } from "../../utils/permission";

const TasksHeader = ({addTaskSuccess}) => {
    const { Task_AddTask } =usePermissions()
    const [showAddTask, setShowAddTask] = useState(false);

    return (
        <Box
            justifyContent={'space-between'}
            display={'flex'}
            alignItems={'center'}

        >
            <AddNewTaskModal showModal={showAddTask} closeModal={() => {
                setShowAddTask(false)
            }} addTaskSuccess={addTaskSuccess}></AddNewTaskModal>
            <Grid container item xs={12} md={12} sx={{ display: "flex" }} spacing={2}>
                {/* <Grid container item xs={12} spacing={2}>
                   
                    <Grid item xs={12} sm={12} md={3}>
                        <FormControl
                            fullWidth

                        >
                            <InputLabel>{STRINGS.Priority}</InputLabel>
                            <Select
                                label={STRINGS.Priority}

                            >
                                <MenuItem value={"Low"}>{"Low"}</MenuItem>
                                <MenuItem value={"Medium"}>{"Medium"}</MenuItem>
                                <MenuItem value={"Urgent"}>{"Urgent"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    
                </Grid> */}
            </Grid>
            {Task_AddTask && <Grid container item md={6} justifyContent={'flex-end'}>
                <IconButton></IconButton>
                <Button onClick={() => {
                    setShowAddTask(true)
                }} variant="contained" sx={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '20px'

                }}>Add New Task</Button>
            </Grid>}
        </Box>
    )

}

export default memo(TasksHeader)