import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { patonaAdmin } from "../../Constants";
const BASE_URL = process.env.REACT_APP_BASE_API_URL

type PaginationFilter = {
    count: number
    pageNumber: number
    pageSize: number
}
type TeamMember = {
    teamMemberId: number
    clientId: number
    zohoId: number
    zoho_ID: any
    employeeId: string
    companyName: string
    firstName: string
    lastName: string
    emailAddress: string
    jobTitle: string
    skillSet: null
    salaryPA: string
    experience: string
    joiningDate: any
    isDeleted: boolean
    resumeURL: any
    employeestatus: string
    profilePhotoURL: string
    isDisabled: boolean
    isPatonaTeamMember: boolean
    dateOfExit: any
    expertise: any
    employeeType: string
    personalMobileNumber: any
    dateOfBirth: any
    maritalStatus: any
    personalEmailID: any
    address: any
    present_Address: any
    permanent_Address: any
    language: any
    linkedin: any
    designation: any
    designationId: any
    zohoAccessToken: any
    photo: any
    workExperiences: any
    emergencyContactDetails: any
    permanentAddress: any
    presentAddress: any
    emergencyContactDetailsFormData: any
    workExperienceFormData: any
}


type Data = {
    paginationFilter?: PaginationFilter
    teamMembers?: Array<TeamMember>
}


type UserListResponse = {
    data: Data
    message: string
    status: number
    successful: boolean
}

type UserListRequest = {
    clientId: number
    pageNumber: number
}

type GenerateUploadLinkRequest = {
    fileName: string
    id: string
}

type GenerateUploadLinkResponse = {
    link: string
}

type FileUploadRequest = {
    link: string
    fileName: string
    id: string
    file: File
};
type FileUploadResponse = null;

type CreateDocumentRequest = {
    title: string
    url: string
    userId: string
    type: string
    fileType: string
    ownerType: string
    roleCanView: string
    roleCanEdit: string
    roleCanDelete: string
    ownerId?: string
    ownerClientId?: number
    createdAt: Date
    createdBy: string
    userName: string
}
export type Document = {
    title: string
    url: string
    userId: string
    type: string
    fileType: string
    ownerType: string
    roleCanView: string
    roleCanEdit: string
    roleCanDelete: string
    ownerId: string
    ownerClientId: number
    createdAt: Date
    createdBy: string
    userName: string
}

type GetDocumentsRequest = {
    title?: string,
    url?: string,
    userId?: string,
    type?: string,
    fileType?: string,
    ownerType?: string,
    ownerClientId?: number
    ownerId?: string,
    userName?: string
}

type GetDocumentsResponse = {
    data: Array<Document>
}


type DownloadDocumentRequest = {
    fileName: string
}

type DownloadDocumentResponse = {
    link: string
}

type DeleteDocumentRequest = {
    id: number
    ownerType: string
    deletedBy: string
}

type DeleteDocumentResponse = {
    status: number
    successful: boolean
    message: string
    data: {
        succeeded: boolean
        errors: Array<string>
    }
}

export const documentSliceApi = createApi({
    reducerPath: 'documentsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers, {
            getState
        }) => {
            const token = (getState() as any).login.accessToken
            if (token) headers.set('Authorization', `Bearer ${token}`)
            return headers
        }
    }),
    tagTypes: ['Docs'],
    endpoints: (builder) => ({
        getUsers: builder.query<UserListResponse, UserListRequest>({
            query: (request) => ({
                url: 'getQualifiedJobCandidateListPerStage',
                method: 'POST',
                body: {
                    pageNumber: request.pageNumber,
                    pageSize: 10,
                    clientId: request.clientId,
                    isDeleted: false,
                    searchValue: "",
                    isDesc: true,
                    columnName: "",
                    role: patonaAdmin,
                    isCompany: false,
                    isPatona: false,
                    employeeType: "",
                    load: 0
                }
            }),
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                return `${endpointName}?clientId=${queryArgs.clientId}`

            },
            onQueryStarted(arg, api) {
                if (arg.pageNumber === 1)
                    api.updateCachedData((candidateResponse) => {
                        candidateResponse.data.teamMembers = []
                    })
            },


            merge: (currentCache, response) => {
                return {
                    ...currentCache,
                    data: {
                        ...currentCache.data,
                        teamMembers: (currentCache?.data?.teamMembers || []).concat(response.data.teamMembers || [])
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.clientId !== previousArg?.clientId
            },

        }),
        generateUploadLink: builder.mutation<GenerateUploadLinkResponse, GenerateUploadLinkRequest>({
            query: (request) => ({
                url: 'generateUploadLink',
                method: 'POST',
                body: {
                    fileName: request.fileName
                }
            })
        }),
        uploadToAzure: builder.mutation<FileUploadResponse, FileUploadRequest>({
            queryFn: async (request, baseApi) => {
                return axios.put(request.link, request.file, {
                    headers: {
                        'X-ms-blob-type': 'BlockBlob',
                        'Content-Type': request.file.type
                    },
                    onUploadProgress: upload => {
                        let uploadProgress = Math.round((100 * upload.loaded) / upload.total)
                        baseApi.dispatch(setUploadProgres({
                            [`${request.fileName}-${request.id}`]: uploadProgress
                        }))
                    }
                })
            }
        }),
        createDocument: builder.mutation<null, CreateDocumentRequest>({
            query: (request) => ({
                url: 'addDocument',
                method: 'POST',
                body: request
            }),
            invalidatesTags: ['Docs']
        }),
        getDocuments: builder.query<GetDocumentsResponse, GetDocumentsRequest>({
            query: (request) => ({
                url: 'GetAllDocuments',
                method: 'POST',
                body: request,

            }),
            providesTags: ['Docs']


        }),
        deleteDocument: builder.mutation<DeleteDocumentResponse, DeleteDocumentRequest>({
            query: (request) => ({
                url: 'deleteDocument',
                method: 'POST',
                body: request,
            }),
            invalidatesTags: ['Docs']
        }),
        downloadDocument: builder.query<DownloadDocumentResponse, DownloadDocumentRequest>({
            query: (request) => ({
                url: 'generateDownloadLink',
                method: 'POST',
                body: request,

            })
        })
    })
})


export const { useGenerateUploadLinkMutation, useUploadToAzureMutation, useCreateDocumentMutation, useGetDocumentsQuery, useDeleteDocumentMutation, useLazyDownloadDocumentQuery } = documentSliceApi


type UploadProgress = {
    [key: string]: number
}

type DocumentState = {
    uploadProgress: UploadProgress
    searchValue: string
}

const initialState: DocumentState = {
    uploadProgress: {},
    searchValue: ""
}


export const documentSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        setUploadProgres: (state, action: PayloadAction<UploadProgress>) => {
            state.uploadProgress = {
                ...state.uploadProgress,
                ...action.payload,
            }
        },

        clearUploadProgress: (state, action: PayloadAction<string>) => {
            delete state.uploadProgress[action.payload]
        },

        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload
        }
    }
})

export const { setUploadProgres, clearUploadProgress, setSearchValue } = documentSlice.actions

export const documents = state => state.documents
export const searchSelector = state => state.documents.searchValue