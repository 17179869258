import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Fab,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clientAdmin, patonaAdmin, teamMember } from "../../Constants";
import { STRINGS } from "../../Strings";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import Search from "../../shared/components/Search/Search";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import { userAccess } from "../../utils/CommonFunction";
import { scrollToTop } from "../People/People.helper";
import ResendInvite from "./ResendInvite";
import SendInvites from "./SendInvites";
import SuccessMessage from "./SuccessMessage";
import {
  disableEnableUser,
  getList,
  getSearch,
  getUsersListList,
  sortMember,
  userGridHeader,
  userGridRow,
} from "./User.helper";

import { usePermissions } from "../../utils/permission";
import EditUserRoles from "./EditUserRole";
import UserMobileDataGrid from "./UserMobileDataGrid";
import { styles } from "./UserStyle";

type UserListProps = {
  getUserListType: string;
  staticCompany: string;
  back?: (boolean) => void;
};

const userListAction = [
  { name: "Edit", action: "getDetails", display: true },
  { name: "Disable", action: "disableTeamMember", display: true },
  { name: "Enable", action: "enableTeamMember", display: false },
];

const userFilterAction = [
  { name: "All", action: "getAllUsers", display: true },
  { name: "Current month", action: "getCurrentMonthUsers", display: true },
  { name: "Active", action: "getActiveUsers", display: true },
  { name: "Inactive", action: "getInactiveUsers", display: true },
];

const UserList: React.FunctionComponent<UserListProps> = (props) => {
  const { getUserListType, staticCompany, back = () => null } = props;

  const alert = useAlert();
  const classes = styles();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [getUsersType, setGetUsersType] =
    React.useState<string>(getUserListType);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [showReInviteModal, setShowReInviteModal] = useState<boolean>(false);
  const [showInviteSuccessModal, setShowInviteSuccessModal] =
    useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [invitedEmail, setInvitedEmail] = useState<string>("");
  const [headerSortAction, setHeaderSortAction] = useState<string>("");
  const [resendData, setResendData] = useState<any>();
  const [userAction, setUserAction] = React.useState<any>(userListAction);
  const [Showfloat, setShowfloat] = React.useState<boolean>(false);
  const [mobileUserList, setMobileUserList] = React.useState<any>([]);
  const [mobilePageLoad, setMobilePageLoad] = React.useState<number>(1);
  const [showMobileSearch, setShowMobileSearch] =
    React.useState<boolean>(false);
  const [anchorEditColumnEl, setAnchorEditColumnEl] =
    React.useState<null | HTMLElement>(null);
  const openEditCoulmn = Boolean(anchorEditColumnEl);
  const handleEditColumnClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEditColumnEl(event.currentTarget);
  };
  const [tabValue, setValue] = useState(0);
  const [userId, setUserId] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const handleEditColumnClose = () => {
    setAnchorEditColumnEl(null);
  };

  const {User_AddNewInvitation, User_DisableUser,User_ListPatona,User_ListClient, User_ListTeamMembers} = usePermissions()

  useEffect(() => {
    getUsersListList(dispatch, true, 1, getUsersType);
  }, []);

  const {
    isUserListLoading,
    userList,
    isPaging,
    page,
    userPageSize,
    userCurrentPage,
  } = useSelector(
    //@ts-ignore
    (state) => state.user
  );
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;

  useEffect(() => {
    if (mobilePageLoad === 1) {
      setMobileUserList(userList);
    } else {
      const newList = [...mobileUserList, ...userList];
      setMobileUserList(newList);
    }
  }, [userList]);

  const loadList = () => {
    const loadPage = mobilePageLoad + 1;
    getList(dispatch, headerSortAction, loadPage, getUsersType);
    setMobilePageLoad(loadPage);
    if (loadPage > 1) {
      setShowfloat(true);
    }
  };

  const invitationSent = () => {
    setShowInviteSuccessModal(true);
  };

  const disableTeamMember = (id) => {
    const payload = {
      clientId: 0,
      userId: id,
      isActive: false,
      fetchRole: getUsersType,
      uId: userAccess().userId,
    };
    disableEnableUser(payload, dispatch);
  };
  const enableTeamMember = (id) => {
    const payload = {
      clientId: 0,
      userId: id,
      isActive: true,
      fetchRole: getUsersType,
      uId: userAccess().userId,
    };
    disableEnableUser(payload, dispatch);
  };

  const enableDisableRowMember = (value) => {
    const updateMenu = userAction?.map((val) => {
      if (val.name === "Disable") {
        val.display = !value ? false : true;
      } else if (val.name === "Enable") {
        val.display = !value ? true : false;
      }
      return val;
    });
    setUserAction(updateMenu);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    event.stopPropagation();
    const userTypeMapping = {
      0: clientAdmin,
      1: patonaAdmin,
      2: teamMember,
    };
    const userType = userTypeMapping[newValue]; // console.log(userType, newValue, "+++++userType");

    setMobileUserList([]);
    getUsersListList(dispatch, true, 1, userType);
    setGetUsersType(userType);
    setValue(newValue);
  };

  const getClientID = async (id) => {
    if (id) {
      setShowEditModal(true);
      setUserId(id);
    }
  };


  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <Box mt={1} mb={1}>
            <Grid container direction="row" alignItems="center">
              <Grid
                item
                xs={12}
                md={2}
                direction="row"
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems="center">
                  {isMobile && (
                    <Grid
                      item
                      xs={12}
                      md={10}
                      sx={{ display: "flex" }}
                      justifyContent={"flex-end"}
                    >
                      <IconButton
                        disableRipple={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditColumnClick(e); //setIsDesc(!isDesc);
                        }}
                      >
                        <FilterAltIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Box>
              </Grid>
              {/* @ts-ignore */}
              <Grid
                container
                sx={{ display: "flex" }}
                justifyContent={"flex-end"}
              >
                {!isMobile && (
                  <>
                    <Grid
                      item
                      xs={12}
                      md={9}
                      sx={{ display: "flex" }}
                      justifyContent={"flex-end"}
                    >
                      <Search
                        getSearch={(value) =>
                          getSearch(dispatch, getUsersType, isFilter, value)
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      sx={{ display: "flex" }}
                      justifyContent={"flex-end"}
                    >
                      {User_AddNewInvitation && <Button
                        onClick={() => setShowInviteModal(true)}
                        variant="contained"
                      >
                        {STRINGS.SendNewInvite}
                      </Button>}
                    </Grid>
                  </>
                )}
                {showInviteModal && (
                  <SendInvites
                    show={showInviteModal}
                    close={() => setShowInviteModal(false)}
                    type={getUsersType}
                    invitedEmail={setInvitedEmail}
                    invitationSent={invitationSent}
                    // userDetails={userDetails}
                  />
                )}
                {showReInviteModal && (
                  <ResendInvite
                    show={showReInviteModal}
                    close={() => setShowReInviteModal(false)}
                    type={getUsersType}
                    invitationSent={invitationSent}
                    data={resendData}
                  />
                )}
                {showInviteSuccessModal && (
                  <SuccessMessage
                    show={showInviteSuccessModal}
                    email={invitedEmail}
                    close={() => setShowInviteSuccessModal(false)}
                    type={getUsersType}
                  />
                )}
                {showEditModal && (
                  <EditUserRoles
                    show={showEditModal}
                    close={() => setShowEditModal(false)}
                    id={userId}
                    // type={getUsersType}
                    // invitedEmail={setInvitedEmail}
                    // invitationSent={invitationSent}
                    // userDetails={userDetails}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
          {!userAccess().client && (
            <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
              {User_ListClient && <Tab label={STRINGS.ClientUsers} />}
              {User_ListPatona && <Tab label={STRINGS.PatonaUsers} />}
              {User_ListTeamMembers && <Tab label={STRINGS.TeamMember} />}
            </Tabs>
          )}
          {isUserListLoading ? (
            skeleton
          ) : isMobile ? (
            <>
              {Showfloat && (
                <Fab
                  sx={{
                    position: "fixed",
                    bottom: (theme) => theme.spacing(10),
                    right: (theme) => theme.spacing(3),
                    backgroundColor: "#ccd5fc",
                  }}
                  onClick={() => scrollToTop(setShowfloat)}
                >
                  <ArrowUpwardIcon />
                </Fab>
              )}
              <Box>
                <>
                  {showMobileSearch && (
                    <Grid
                      item
                      xs={12}
                      md={10}
                      sx={{ display: "flex" }}
                      justifyContent={"flex-end"}
                    >
                      <Search
                        getSearch={(value) =>
                          getSearch(dispatch, getUsersType, isFilter, value)
                        }
                        closeSearch={() =>
                          setShowMobileSearch(!showMobileSearch)
                        }
                      />
                    </Grid>
                  )}
                  {!showMobileSearch && (
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", mt: 1 }}
                      justifyContent={"space-between"}
                    >
                      <Button
                        onClick={() => setShowInviteModal(true)}
                        variant="contained"
                      >
                        {STRINGS.SendNewInvite}
                      </Button>
                      <IconButton sx={{ width: 10 }}>
                        <SearchIcon
                          onClick={() => setShowMobileSearch(!showMobileSearch)}
                        />
                      </IconButton>
                    </Grid>
                  )}
                </>
                <UserMobileDataGrid
                  row={userGridRow(mobileUserList, getUsersType, User_AddNewInvitation)}
                  actionList={User_DisableUser ? userAction:[]}
                  getDetails={getClientID}
                  cellClick={(data) => {
                    setResendData(data);
                    setShowReInviteModal(true);
                  }}
                  disableTeamMember={disableTeamMember}
                  enableTeamMember={enableTeamMember}
                  enableDisableRow={enableDisableRowMember}
                />
              </Box>
              {isPaging && isMobile && mobilePageLoad < page && (
                <Box mt={1} mb={3} textAlign="center">
                  <Button
                    variant="outlined"
                    size="small"
                    color="warning"
                    onClick={loadList}
                  >
                    {STRINGS.LoadMore}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <>
              {User_ListClient && <TabPanel value={tabValue} index={0}>
                <DataGrid
                  header={userGridHeader(getUsersType)}
                  row={userGridRow(userList, getUsersType,User_AddNewInvitation)}
                  headerFilterActionList={userFilterAction}
                  actionList={User_DisableUser ? userAction:[]}
                  getDetails={getClientID}
                  sortTable={(sort, key) => {
                    sortMember(
                      dispatch,
                      getUsersType,
                      isFilter,
                      "",
                      sort,
                      key,
                      userPageSize
                    );
                  }}
                  showPaging={isPaging}
                  page={page}
                  getPageList={(page) => {
                    getList(
                      dispatch,
                      headerSortAction,
                      page,
                      getUsersType,
                      userPageSize
                    );
                  }}
                  headerSort={(action) => {
                    setHeaderSortAction(action);
                    getList(dispatch, action, 1, getUsersType, userPageSize);
                  }}
                  cellClick={(data) => {
                    setResendData(data);
                    setShowReInviteModal(true);
                  }}
                  disableTeamMember={disableTeamMember}
                  enableTeamMember={enableTeamMember}
                  enableDisableRow={enableDisableRowMember}
                  rowPerPage={(row) => {
                    getList(dispatch, headerSortAction, 1, getUsersType, row);
                  }}
                  defaultRowPerPageCount={userPageSize}
                  currentPage={userCurrentPage}
                />
              </TabPanel>}
              {User_ListPatona &&<TabPanel value={tabValue} index={1}>
                <DataGrid
                  header={userGridHeader(getUsersType)}
                  row={userGridRow(userList, getUsersType,User_AddNewInvitation)}
                  headerFilterActionList={userFilterAction}
                  actionList={User_DisableUser ? userAction:[]}
                  getDetails={getClientID}
                  sortTable={(sort, key) => {
                    sortMember(
                      dispatch,
                      getUsersType,
                      isFilter,
                      "",
                      sort,
                      key,
                      userPageSize
                    );
                  }}
                  showPaging={isPaging}
                  page={page}
                  getPageList={(page) => {
                    getList(
                      dispatch,
                      headerSortAction,
                      page,
                      getUsersType,
                      userPageSize
                    );
                  }}
                  headerSort={(action) => {
                    setHeaderSortAction(action);
                    getList(dispatch, action, 1, getUsersType, userPageSize);
                  }}
                  cellClick={(data) => {
                    setResendData(data);
                    setShowReInviteModal(true);
                  }}
                  disableTeamMember={disableTeamMember}
                  enableTeamMember={enableTeamMember}
                  enableDisableRow={enableDisableRowMember}
                  rowPerPage={(row) => {
                    getList(dispatch, headerSortAction, 1, getUsersType, row);
                  }}
                  defaultRowPerPageCount={userPageSize}
                  currentPage={userCurrentPage}
                />
              </TabPanel>}
              {User_ListTeamMembers &&<TabPanel value={tabValue} index={2}>
                <DataGrid
                  header={userGridHeader(getUsersType)}
                  row={userGridRow(userList, getUsersType,User_AddNewInvitation)}
                  headerFilterActionList={userFilterAction}
                  actionList={userAction}
                  getDetails={getClientID}
                  sortTable={(sort, key) => {
                    sortMember(
                      dispatch,
                      getUsersType,
                      isFilter,
                      "",
                      sort,
                      key,
                      userPageSize
                    );
                  }}
                  showPaging={isPaging}
                  page={page}
                  getPageList={(page) => {
                    getList(
                      dispatch,
                      headerSortAction,
                      page,
                      getUsersType,
                      userPageSize
                    );
                  }}
                  headerSort={(action) => {
                    setHeaderSortAction(action);
                    getList(dispatch, action, 1, getUsersType, userPageSize);
                  }}
                  cellClick={(data) => {
                    setResendData(data);
                    setShowReInviteModal(true);
                  }}
                  disableTeamMember={disableTeamMember}
                  enableTeamMember={enableTeamMember}
                  enableDisableRow={enableDisableRowMember}
                  rowPerPage={(row) => {
                    getList(dispatch, headerSortAction, 1, getUsersType, row);
                  }} // defaultRowPerPageCount={userPageSize} // currentPage={userCurrentPage}
                />
              </TabPanel>}
            </>
          )}
          <Menu
            id="menu"
            anchorEl={anchorEditColumnEl}
            keepMounted
            open={openEditCoulmn}
            onClose={handleEditColumnClose}
            onClick={handleEditColumnClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 15,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {userFilterAction?.map((value) => {
              return (
                <MenuItem
                  onClick={() => {
                    setHeaderSortAction(value.action);
                    getList(dispatch, value.action, 1, getUsersType);
                  }}
                >
                  {value?.name}
                </MenuItem>
              );
            })}
          </Menu>
        </Container>
      </Grid>
    </>
  );
};

export default UserList;
