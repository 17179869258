import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { userAccess } from "../../../../utils/CommonFunction";

import { feedbackIntegration } from "@sentry/react";
import { usePermissions } from "../../../../utils/permission";
import AttendanceIcon from "./icons/AttendanceIcon";
import ClientIcon from "./icons/ClientIcon";
import DashboardIcon from "./icons/DashboardIcon";
import DocumentIcon from "./icons/DocumentIcon";
import HireIcon from "./icons/HireIcon";
import InvoiceIcon from "./icons/InvoiceIcon";
import OpportunitiesIcon from "./icons/OpportunitiesIcon";
import PerformanceIcon from "./icons/PerformanceIcon";
import SendFeedbackIcon from "./icons/SendFeedbackIcon";
import StorefrontIcon from "./icons/ShopIcon";
import TasksIcon from "./icons/TasksIcon";
import TeamMemberIcon from "./icons/TeamMemberIcon";

import TimeIcon from "./icons/TimeIcon";
import UserIcon from "./icons/UserIcon";
const feedback = feedbackIntegration({
  // Disable injecting the default widget
  autoInject: false,

});

const NavItems = (props) => {
  const { pathname } = useLocation();
  const { isTeamMember, zohoId, empId, client, role } = userAccess();
  const {
    Hire,
    Users,
    Invoice,
    Shop,
    Attendance,
    Opportunity,
    Client,
    Task,
    Performance,
    Documents,
    TeamMember,
    TimeManagement
  } = usePermissions();
  const routes = [
    {
      path: "/dashboard",
      label: "Dashboard",
      IconComponent: DashboardIcon,
    },
    {
      path: "/hire",
      label: "Hire",
      IconComponent: HireIcon,
      activeWhen: ["/hire", "/job-details", "/hire-now"],
      condition: () => Hire,
    },
    {
      path: "/team-members",
      label: "Team Members",
      IconComponent: TeamMemberIcon,
      activeWhen: ["/team-members", "/edit-member", "/add-member"],
      condition: () => TeamMember,
    },
    {
      path: "/client",
      label: "Clients",
      IconComponent: ClientIcon,
      activeWhen: ["/client", "/edit-client"],
      condition: () => Client,
    },
    {
      path: "/users",
      label: "Users",
      IconComponent: UserIcon,
      condition: () => Users,
    },
    {
      path: "/opportunities",
      label: "Opportunities",
      IconComponent: OpportunitiesIcon,
      condition: () => Opportunity,
    },

    {
      path: "/time",
      label: "Time Management",
      IconComponent: TimeIcon,
      condition: () => TimeManagement,
      subRoutes: [
        {
          path: "/leave",
          label: "Leave",
        },
        {
          path: "/timesheets",
          label: "Timesheets",
        },
      ],
    },
    {
      path: isTeamMember ? `/performance/${empId}` : "/performance",
      label: "Performance Pulse",
      IconComponent: PerformanceIcon,
      condition: () => Performance
    },
    {
      path: "/attendance",
      label: "Attendance",
      IconComponent: AttendanceIcon,
      condition: () => Attendance,
    },
    {
      path: "/tasks",
      label: "Tasks",
      IconComponent: TasksIcon,
      condition: () => Task,
    },
    {
      path: "/documents",
      label: "Documents",
      IconComponent: DocumentIcon,
      condition: () => Documents,
    },
    {
      path: "/invoices",
      label: "Invoices",
      IconComponent: InvoiceIcon,
      condition: () => Invoice,
    },
    {
      path: "/shop",
      label: "Shop",
      IconComponent: StorefrontIcon,
      condition: () => Shop,
    },
  ];
  const [openSubMenus, setOpenSubMenus] = useState({
    "/time": true,
  });

  const handleFeedbackClick = async (e) => {
    e.preventDefault()

    const { firstName, lastName, email } = userAccess();

    const feedback = feedbackIntegration({
      // Disable injecting the default widget
      autoInject: false,

      // Customise the title of the feedback widget
      showBranding: false,
      formTitle: 'Send feedback',
      submitButtonLabel: 'Send feedback',
      messagePlaceholder: 'What is your feedback? Did you find a bug? What did you expect?',
      successMessageText: 'Thank you for your feedback! The development team have been notified.',

      // Configure the user
      // NOTE: This is not currently working and I'm not sure why
      useSentryUser: {
        name: firstName + ' ' + lastName,
        email: email,
      },
    });

    const form = await feedback.createForm({
      useSentryUser: {
        name: "fullName",
        email: "email",
      },
    });
    form.appendToDom();
    form.open();
  }

  const handleNavLinkClick = (event, route) => {
    if (route.subRoutes) {
      event.preventDefault();
      setOpenSubMenus((prevOpenSubMenus) => ({
        ...prevOpenSubMenus,
        [route.path]: !prevOpenSubMenus[route.path],
      }));
    } else {
      if (props.close) {
        props.close();
      }
    }
  };

  const isRouteActive = (route) => {
    if (route.subRoutes) {
      return null;
    }
    // Check if the current pathname is included in the route's activeWhen array
    if (route.activeWhen && route.activeWhen.includes(pathname)) {
      return true;
    }

    return false;
  };

  return (
    <nav style={{ overflow: "auto", height: "calc(100vh - 120px)" }}>
      {routes.map((route) => {
        if (route.condition && !route.condition()) return null;
        const isActive = isRouteActive(route, pathname);

        const Icon = route.IconComponent;
        const isSubMenuOpen = !!openSubMenus[route.path];

        return (
          <div key={route.path} className="nav-item">
            <NavLink
              to={route.path}
              exact={"true"}
              className={isActive ? "active" : ""}
              onClick={(e) => handleNavLinkClick(e, route)}
            >
              <Icon active={isActive} />
              {route.label}
              {route.subRoutes && (
                <span className="sub-menu-toggle">
                  {isSubMenuOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
              )}
            </NavLink>
            {route.subRoutes && isSubMenuOpen && (
              <ul className="sub-menu">
                {route.subRoutes.map((subRoute) => (
                  <li key={subRoute.path}>
                    <NavLink
                      to={subRoute.path}
                      className={isRouteActive(subRoute, pathname)}
                      onClick={props.close}
                    >
                      {subRoute.label}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      })}
      <div className="nav-item">
      <NavLink
        to="/feedback"
        className={""}
        onClick={handleFeedbackClick}
      >
        <SendFeedbackIcon  />
        Send Feedback
      </NavLink>
      </div>
    </nav>
  );
};

export default NavItems;
