import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { STRINGS } from "../../Strings";
import { useStyles } from "./style";
import SelectBox from "../../shared/components/SelectBox/SelectBox";
import { referForJob } from "../../services/Opportunities";
import { useAlert } from "react-alert";
import SelectFile from "./SelectFile";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.2)",
  padding: "24px",
  outline: "none",
  width: "auto",
  maxWidth: "40vw",
  maxHeight: "90vh",
};

const ReferModal = ({ handleOpenClose, open, jobDetails }) => {
  const classes = useStyles();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object({
    candidateName: Yup.string().required("Required"),
    jobTitle: Yup.string().required("Required"),
    experienceLevel: Yup.string().required("Required"),
    currentlyWorking: Yup.string().required("Required"),
    emailId: Yup.string().email("Invalid email address").required("Required"),
    contactNumber: Yup.string().required("Required"),
  });

  const experienceLevel = [
    { id: 1, name: "1" },
    { id: 2, name: "2" },
    { id: 3, name: "3" },
    { id: 4, name: "4" },
  ];

  const formik = useFormik({
    initialValues: {
      candidateName: "",
      jobTitle: "",
      experienceLevel: "",
      currentlyWorking: "",
      emailId: "",
      contactNumber: "",
      resume: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("candidateName", values?.candidateName);
      formData.append("jobTitle", values?.jobTitle);
      formData.append("currentlyWorking", values?.currentlyWorking);
      formData.append("expectedJoiningDate", jobDetails?.expectedJoiningDate);
      formData.append("client", jobDetails?.company_Name);
      formData.append("emailId", values?.emailId);
      formData.append("contactNumber", values?.contactNumber);
      if (values?.resume) {
        formData.append("resumeFile", values.resume);
        //@ts-ignore
        formData.append("resumeFileName", values.resume.name);
      }
      setLoading(true);
      const response: any = await referForJob(formData);
      if (response?.status === 200) {
        setLoading(false);
        handleOpenClose();
        resetForm();
        alert.success("Reference sent successfully.");
      } else {
        const errorMessage =
          response?.data?.errorMessage ||
          "An error occurred. Please try again.";
        alert.error(errorMessage);
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      formik.setFieldValue("resume", file);
    }
  };

  const handleClear = () => {
    formik.setFieldValue("resume", null);
    const fileInput = document.getElementById(
      "resume-upload"
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleOpenClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box component="form" noValidate onSubmit={formik.handleSubmit}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            textAlign="center"
          >
            {STRINGS.Refer}
          </Typography>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Candidate's name*"
                name="candidateName"
                value={formik.values.candidateName}
                onChange={formik.handleChange}
                error={
                  formik.touched.candidateName &&
                  Boolean(formik.errors.candidateName)
                }
                helperText={
                  formik.touched.candidateName && formik.errors.candidateName
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Job title*"
                name="jobTitle"
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                error={
                  formik.touched.jobTitle && Boolean(formik.errors.jobTitle)
                }
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                label="Experience Level"
                name="experienceLevel"
                value={formik.values.experienceLevel}
                onChange={formik.handleChange}
                options={experienceLevel.map((level) => ({
                  id: level.id,
                  value: level.name,
                  label: level.name,
                }))}
                error={formik.errors.experienceLevel}
                touched={formik.touched.experienceLevel}
                ariaLabel="Experience Level"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Currently working"
                name="currentlyWorking"
                value={formik.values.currentlyWorking}
                onChange={formik.handleChange}
                error={
                  formik.touched.currentlyWorking &&
                  Boolean(formik.errors.currentlyWorking)
                }
                helperText={
                  formik.touched.currentlyWorking &&
                  formik.errors.currentlyWorking
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email Id*"
                name="emailId"
                value={formik.values.emailId}
                onChange={formik.handleChange}
                error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                helperText={formik.touched.emailId && formik.errors.emailId}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Contact number*"
                name="contactNumber"
                value={formik.values.contactNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactNumber &&
                  Boolean(formik.errors.contactNumber)
                }
                helperText={
                  formik.touched.contactNumber && formik.errors.contactNumber
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFile
                id="resume-upload"
                label="Upload Resume"
                handleFileChange={handleFileChange}
                selectedFile={formik.values.resume}
                handleClear={handleClear}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="text"
                  sx={{ mt: 2, color: "#A16AE5" }}
                  onClick={() => {
                    handleOpenClose();
                    formik.resetForm();
                  }}
                >
                  {STRINGS.Cancel}
                </Button>
                {!loading ? (
                  <Button variant="contained" sx={{ mt: 2 }} type="submit">
                    {STRINGS.Submit}
                  </Button>
                ) : (
                  <Button
                    disabled
                    variant="contained"
                    sx={{
                      mt: 2,
                    }}
                  >
                    <CircularProgress size={18} />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default ReferModal;
