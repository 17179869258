import { Box, Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material"
import { Form, Formik } from "formik"
import { memo, useState } from "react"
import { useAlert } from "react-alert"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { addTask } from "../../services/Tasks"
import FormikInput from "../../shared/components/FormikInput"
import { userAccess } from "../../utils/CommonFunction"
import { usePermissions } from "../../utils/permission"



const initialValues = {
    TaskType: 'individualTask',
    Title: '',
    Summary: '',
    ClientId: '',
    Priority: '',
    CreatedAt: '',
    AssignTo: 1,

}

const validationSchema = yup.object({
    Title: yup.string().required("Task title is required"),
    Summary: yup.string().required("Summary is required"),
    Priority: yup.string().required("Please select a priority"),
    AssignTo: yup.string().required('Please select Assignee')

});

const OPTIONS_ADMIN = [
    { name: "Client", value: 2 },
    { name: 'Individual', value: 3 }]
const OPTIONS_CLIENT_ADMIN = [
    { name: "Account Manager", value: 1 },
    { name: 'Individual', value: 3 }
]

const AddNewTaskModal = ({ showModal, closeModal, addTaskSuccess }) => {

    const {Task_AddTaskClient} = usePermissions()

    const [addTaskLoading, setAddTaskLoading] = useState(false)
    const { allWorkableClients, isLoading } = useSelector(
        //@ts-ignore
        (state) => state.hire
    );

    const {
        isUserListLoading,
        userList,

    } = useSelector((state) =>
        //@ts-ignore
        state.user
    )
    const { clientAdminLoading, clientAdmins } = useSelector(
        //@ts-ignore
        (state) => state.client
    );
    const alert = useAlert()


    const [assignToType, setAssignToType] = useState(Task_AddTaskClient ? 1 : 2);

    const postAddTask = async (values) => {
        const request = {
            Title: values.Title,
            Summary: values.Summary,
            Priority: values.Priority,
            TaskType: values.TaskType,
            AssigneeRole: userAccess().role,
            AssigneeUser: values.AssigneeUser || userAccess().userId,
            ClientId: assignToType === 1 ? 999 : values.ClientId
        }
        try {

            setAddTaskLoading(true)
            await addTask(request)
            closeModal()
            alert.success('Task added successfully')
            addTaskSuccess()

        } catch (e) {
            alert.error('Adding task failed ' + e)
        }
        setAddTaskLoading(false)

    }

    return (
        <Dialog open={showModal} onClose={closeModal} maxWidth='md' sx={{

        }}>
            <DialogTitle variant="h4" fontWeight={400} fontSize={'32px'} justifyContent={'center'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Typography fontSize={'24px'} lineHeight={'32px'} fontWeight={700}>Add New Task</Typography>
                <Typography fontSize={'14px'} lineHeight={'20px'} fontWeight={700}>You can rearrange the activity order as per your requirement.</Typography>

            </DialogTitle>

            <DialogContent sx={{
                py: 0,
                pb: '1rem',
                width: '50vw'
            }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={(data) => postAddTask(data)}
                >
                    {(formikProps) => (
                        <Form>
                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} md={12}>
                                    <FormikInput
                                        label="Task*"
                                        name="Title"
                                        formikProps={formikProps}
                                        options={undefined}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormikInput
                                        label="Describe the task*"
                                        name="Summary"
                                        formikProps={formikProps}
                                        options={undefined}
                                        multiline
                                        rows={3}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikInput
                                        label="Assign to*"
                                        name="AssignTo"
                                        className="common-select-dropdown"
                                        formikProps={formikProps}
                                        schemaChange
                                        schemaOnChangeFun={(value) => {
                                            setAssignToType(value)
                                        }}
                                        options={
                                        Task_AddTaskClient ? OPTIONS_CLIENT_ADMIN : OPTIONS_ADMIN
                                        }
                                        inputType="select"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormikInput
                                        label="Priority*"
                                        name="Priority"
                                        className="common-select-dropdown"
                                        formikProps={formikProps}
                                        options={[
                                            { name: "Low", value: 'Low' },
                                            { name: "Medium", value: 'Medium' },
                                            { name: "Urgent", value: "Urgent" },

                                        ]}
                                        inputType="select"
                                        fullWidth
                                    />
                                </Grid>
                                {!Task_AddTaskClient && assignToType === 2 && <Grid item xs={12} sm={12}>
                                    <FormikInput
                                        label="Client*"
                                        name="client"
                                        className="common-select-dropdown"
                                        formikProps={formikProps}
                                        options={allWorkableClients.map((item) => {
                                            return { name: item.name, value: item.id };
                                        })}
                                        schemaChange
                                        schemaOnChangeFun={(value) => {
                                            formikProps.setFieldValue("clientId", value);
                                        }}
                                        inputType="select"
                                        fullWidth
                                    />
                                </Grid>}
                                {!Task_AddTaskClient && assignToType === 3 && <Grid item xs={12} sm={12}>
                                    <FormikInput
                                        label="Individual*"
                                        name="AssigneeUser"
                                        className="common-select-dropdown"
                                        formikProps={formikProps}
                                        options={userList.map((item) => {
                                            return { name: item.userName, value: item.userId };
                                        })}
                                        schemaChange
                                        schemaOnChangeFun={(value) => {
                                            formikProps.setFieldValue("AssigneeUser", value);
                                        }}
                                        inputType="select"
                                        fullWidth
                                    />
                                </Grid>}
                                {Task_AddTaskClient && assignToType === 3 && <Grid item xs={12} sm={12}>
                                    <FormikInput
                                        label="Individual*"
                                        name="AssigneeUser"
                                        className="common-select-dropdown"
                                        formikProps={formikProps}
                                        options={clientAdmins.map((item) => {
                                            return { name: item.firstName, value: item.uId };
                                        })}
                                        schemaChange
                                        schemaOnChangeFun={(value) => {
                                            formikProps.setFieldValue("AssigneeUser", value);
                                        }}
                                        inputType="select"
                                        fullWidth
                                    />
                                </Grid>}
                            </Grid>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                <Button
                                    variant="text"
                                    sx={{ mt: 2 }}
                                    onClick={closeModal}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    disabled={isLoading || addTaskLoading}
                                    variant="contained"
                                    sx={{ mt: 2 }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Form>

                    )}

                </Formik>




            </DialogContent>
        </Dialog>)
}

export default memo(AddNewTaskModal)